// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export default {
  apiKey: "AIzaSyB7L5qVk47zx7wiyv8LBC3vEJoY5ZQxAoA",
  authDomain: "auth.zkparty.net",
  databaseURL: "https://trustedsetup-a86f4.firebaseio.com",
  projectId: "trustedsetup-a86f4",
  storageBucket: "trustedsetup-a86f4.appspot.com",
  messagingSenderId: "727081587581",
  appId: "1:727081587581:web:fe803d078d9becafa57214",
  measurementId: "G-NGZ0DZC0YZ"
};