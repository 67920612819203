import * as React from 'react';
import styled from "styled-components";
import { NormalBodyText, textColor } from './../styles';

const FooterText = styled.div`
  font-family: Avenir Next, sans-serif;
  font-size: 20px;
  color: #fff;
  font-weight: 400;
`

export default function Footer(props: any) {
    const body = (
      <div style={{ marginLeft: '111px' }}>
      </div>
    );

    return body;
}
